export enum InputTypeEnum {
	TEXT = 'TEXT',
	NUMBER = 'NUMBER',
	SELECT = 'SELECT',
	BOOLEAN = 'BOOLEAN'
}

export enum InputStatusEnum {
	ENABLED = 'ENABLED',
	DISABLED = 'DISABLED'
}